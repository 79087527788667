<template>
  <v-card flat>
    <v-card-text class="pt-0">
      <v-container fluid class="py-0">
        <v-row dense>
          <v-col cols="12" class="title">
            {{division.teamLabelSingle}}: {{team.name}}
          </v-col>
          <v-col cols="12" v-if="!manualW">
            <v-chip :class="{success: status.rStarters === status.starters}">{{status.rStarters}} of {{status.starters}} Required Players</v-chip>
            <v-chip v-if="status.alternates > 0 || status.rAlternates > 0">{{status.rAlternates}}{{status.alternates ? ` of ${status.alternates}` : ''}} Alternates</v-chip>
          </v-col>
          <v-col cols="12">
            <v-card v-if="iRoster.length > 0 && !manualW">
              <v-list :two-line="editing">
                <v-slide-y-transition
                  class="py-0"
                  group
                >
                  <template v-for="(p, i) in iRoster">
                    <v-divider
                      v-if="i !== 0"
                      :key="`${i}-divider`"
                    ></v-divider>
                    <v-list-item :key="`${i}-${p.ppId}`">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{getPlayerText(p)}}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="editing" class="clickable caption" @click.stop="editPlayer(p)">Click to {{p.jersey ? 'edit the' : 'add a'}} jersey number</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-scroll-x-transition>
                          <v-btn color="error" x-small text icon v-if="editing" @click="drop(p)">
                            <v-icon>fas fa-trash</v-icon>
                          </v-btn>
                        </v-scroll-x-transition>
                      </v-list-item-action>
                    </v-list-item>
                  </template>

                </v-slide-y-transition>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-window v-model="window" touchless>
              <v-window-item :key="0">

              </v-window-item>
              <v-window-item :key="1">
                <v-btn
                  color="color3"
                  text
                  @click.stop="editing = true"
                  v-if="!editing && currentTeam.length === 0"
                >
                  Add a Player
                </v-btn>
              </v-window-item>
              <v-window-item :key="2">
                <v-select
                  :key="availablePlayers.length"
                  label="Select A Player"
                  v-if="parentTeam && window === 2"
                  :loading="!parentTeam"
                  :items="availablePlayers"
                  :item-text="item => getPlayerText(item)"
                  item-value="ppId"
                  return-object
                  @change="item => onChange(item)"
                  hide-details
                  color="color3"
                  item-color="color3"
                  ref="select"
                  outlined
                  :disabled="full"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      There are no players on the {{parentTeam.name}} roster
                    </v-list-item>
                  </template>
                  <template v-slot:append-item>
                    <v-divider></v-divider>
                    <v-list-item @click.stop="manualW = 3">
                      <v-btn color="color3" text x-small>
                        player search
                      </v-btn>
                    </v-list-item>
                  </template>
                </v-select>
                <v-fab-transition>
                  <v-btn
                    v-if="availablePlayers.length"
                    color="color3"
                    text x-small
                    @click.stop="addAll"
                  >Add All</v-btn>
                </v-fab-transition>
              </v-window-item>
              <v-window-item :key="3">
                <player-lookup
                  @player-selected="onPlayerSelected"
                  :clearOnSelect="true"
                  :membersOnly="tournament.membersOnly"
                  :tId="tournament.id"
                ></player-lookup>
              </v-window-item>
              <v-window-item :key="4">
                <div v-if="manualPlayer">
                  <search-result-item
                    :player="manualPlayer"
                  ></search-result-item>
                  <v-text-field
                    label="Jersey Number"
                    outlined
                    placeholder="Enter A Jersey Number"
                    v-model="jersey"
                  ></v-text-field>
                  <template v-if="false">
                    <div class="text-h6">Should we add {{manualPlayer.fullName}} to the {{parentTeam.name}} roster permnnently? Or just for this {{division.eventType.toLowerCase()}}?</div>
                    <v-radio-group v-model="addToRoster">
                      <v-radio color="success" :label="`Add to ${parentTeam.name} roster`" :value="true"></v-radio>
                      <v-radio color="success" :label="`Just this ${division.eventType.toLowerCase()}`" :value="false"></v-radio>
                    </v-radio-group>
                  </template>
                </div>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions v-if="false">
      <v-btn
        color="color3Text color3--text"
        small fab @click.stop="backClick"
      >
        <v-icon>fas fa-caret-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          color="error white--text"
          fab small
          @click="cancelClick"
          :disabled="iloading"
          v-if="editing"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
          :key="currentBtn.key"
          :color="currentBtn.color"
          :disabled="currentBtn.disabled"
          @click.stop="currentBtn.click"
          :loading="iloading"
          fab small
        >
          <v-icon>{{currentBtn.icon}}</v-icon>
        </v-btn>
      </v-fab-transition>

    </v-card-actions>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="300px"
      transition="dialog-transition"
    >
      <v-card v-if="selectedPlayer">
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>{{selectedPlayer.name}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Jersey Number"
            v-model.number="selectedPlayer.jersey"
            clearable
            @change="dialog = false"
            autofocus
            type="number"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="success white--text" fab small @click.stop="dialog = false">
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="inactiveDialog"
      max-width="300px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-text>
          <div>
            <h3 class="headline">Oops</h3>
            <div>{{inactiveMsg.text}}</div>
            <div class="pt-3" v-if="false">
              <v-btn color="color3 color3Text--text" small :href="joinNow">{{inactiveMsg.btn}}</v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="color3" @click.stop="inactiveDialog = false">Got it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { symmetricDifference } from '@/helpers/SetFunctions'
import { firstBy } from 'thenby'
import { formatArrayOr } from '@/Filters'
const PlayerLookup = () => import('@/components/Player/Search/PlayerLookup.vue')
// const PlayerLookup = () => import('@/components/Tournament/Registration/New/PlayerLookup.vue')
const SearchResultItem = () => import('@/components/Player/Search/SearchResultItem.vue')

export default {
  props: ['team', 'parentTeam', 'active', 'loading'],
  data () {
    return {
      iRoster: [],
      editing: false,
      selectedPlayer: null,
      iloading: false,
      manualW: false,
      manualPlayer: false,
      addToRoster: true,
      jersey: null,
      dialog: false,
      inactiveDialog: false,
      inactiveMsg: {
        text: 'The member you selected has an expired or invalid membership',
        btn: 'Renew Now'
      }
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    window () {
      return this.manualW || (this.editing && !this.full ? 2 : !this.editing && this.currentTeam.length === 0 ? 1 : 0)
    },
    division () {
      return this.team && this.tournament.getDivisionByTeam(this.team.id)
    },
    full () {
      return this.iRoster.length === this.division.numAllowedOnRoster && !this.division.isNcaa
    },
    status () {
      return {
        roster: this.iRoster.length,
        starters: this.division.numOfPlayers,
        get rStarters () {
          return this.roster < this.starters ? this.roster : this.starters
        },
        alternates: this.division.numAllowedOnRoster - this.division.numOfPlayers,
        get rAlternates () {
          return this.roster <= this.starters ? 0 : this.roster - this.starters
        }
      }
    },
    dirtyOG () {
      const a = new Set(this.currentTeam)
      const b = new Set(this.takenPlayers)
      const d = symmetricDifference(a, b)
      return d.size > 0
    },
    dirty () {
      const a = JSON.stringify(this.team.players.map(m => {
        return {
          ppId: m.ppId,
          name: m.name,
          jersey: m.jersey
        }
      }))
      return a !== JSON.stringify(this.iRoster)
    },
    currentTeam () {
      return this.team.players.map(m => m.ppId)
    },
    takenPlayers () {
      return this.iRoster.map(m => m.ppId)
    },
    availablePlayers () {
      const p = this.parentTeam ? this.parentTeam.roster.filter(f => !this.takenPlayers.includes(f.playerProfileId)) : []
      return p.sort(firstBy('name'))
    },
    currentBtn () {
      return this.editing ? {
        icon: 'fas fa-save',
        color: 'success white-text',
        disabled: !this.dirty && this.manualW !== 4,
        key: 'save',
        click: this.save
      } : {
        icon: 'fas fa-pencil',
        color: 'color3Text color3--text',
        key: 'edit',
        click: () => { this.editing = true }
      }
    },
    dto () {
      return {
        playerProfileIds: this.iRoster.map(m => m.ppId),
        tags: this.iRoster.map(m => m.jersey ? `${m.ppId}-jersey-${m.jersey}` : false).filter(f => f),
        addToParent: this.iRoster.filter(f => f.addToParent).map(m => m.ppId)
      }
    }
  },
  methods: {
    init () {
      this.manualW = false
      this.editing = false
      this.addToRoster = true
      this.iRoster = this.team.players.map(m => {
        return {
          ppId: m.ppId,
          name: m.name,
          jersey: m.jersey
        }
      })
      this.$emit('current-btn-change', this.currentBtn)
    },
    editPlayer (p) {
      this.selectedPlayer = p
      this.dialog = true
    },
    cancelClick () {
      this.init()
    },
    getPlayerText (p) {
      const j = p.jersey ? ` #${p.jersey}` : ''
      return `${p.name}${j}`
    },
    onChange (item) {
      const i = this.iRoster
      const x = i.find(f => f.ppId === item.ppId)
      if (!x) {
        i.push({
          ppId: item.playerProfileId,
          name: item.name,
          jersey: item.jersey,
          addToRoster: !!item.addToRoster
        })
      }
      this.$refs.select && this.$refs.select.blur()
      this.selectedPlayer = null
    },
    drop (p) {
      this.iRoster = this.iRoster.filter(f => f.ppId !== p.ppId)
    },
    backClick () {
      if (this.manualW) {
        if (this.manualW === 4) {
          this.manualW--
        } else {
          this.manualW = null
        }
        return
      }
      this.$emit('back-click')
    },
    save () {
      if (this.manualW === 4) {
        return this.saveManual()
      }
      this.iloading = true
      this.$VBL.roster.submit(this.team.id, this.dto)
        .then(r => {
          this.team.update(r.data)
          this.cancelClick()
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.iloading = false
        })
    },
    saveManual () {
      this.manualW = null
      this.onChange({
        playerProfileId: this.manualPlayer.id,
        name: this.manualPlayer.fullName,
        addToParent: this.addToRoster,
        jersey: this.jersey
      })
      this.manualPlayer = null
      this.jersey = null
    },
    onPlayerSelected (p) {
      if (this.tournament.membersOnly) {
        var levelIsGood = true
        if (this.tournament.membershipLevels && this.tournament.membershipLevels.length) {
          var al = this.tournament.membershipLevels.map(m => m.toLowerCase())
          var l = p.levels.map(f => f.toLowerCase())
          var good = l.find(f => al.includes(f))
          if (!good) levelIsGood = false
        }

        if (!p.active || !levelIsGood) {
          this.inactiveMsg = !p.active ? {
            text: 'The member you selected has an expired or invalid membership',
            btn: 'Renew Now'
          } : {
            text: `This event requires a ${formatArrayOr(this.tournament.membershipLevels)} membership`,
            btn: 'Join Now'
          }

          this.$nextTick(() => {
            this.selectedResult = null
            this.inactiveDialog = true
            this.prevTerm = this.term
          })
          return
        }
      }
      this.manualPlayer = p
      this.manualW = 4
    },
    addAll () {
      this.availablePlayers.forEach(p => {
        this.onChange(p)
      })
    }
  },
  watch: {
    active: 'init',
    iloading: function (v) {
      this.$emit('loading-change', v)
    },
    editing: function (v) {
      this.$emit('edit-change', v)
      this.$emit('current-btn-change', this.currentBtn)
    },
    currentBtn: {
      handler () {
        this.$emit('current-btn-change', this.currentBtn)
      },
      deep: true
    }
  },
  components: {
    PlayerLookup,
    SearchResultItem
  },
  mounted () {
    this.init()
  }
}
</script>
